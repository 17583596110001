import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer className="footer footer-dark">
      <div className="container">
        <div className="footer--left-links">
          {' '}
          © {new Date().getFullYear()} Built by
          {` `}
          <a href="https://pegadigital.com">
            Pega <span className="color-primary">Digital.</span>
          </a>
        </div>
        <div className="footer--right-links">
          <Link to="/privacy">Privacy</Link>
          {' | '}
          <Link to="/terms">Terms &amp; Conditions</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
