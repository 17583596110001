import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import './navbar.scss';
import logo from '../images/Pega-Digital-Horizontal.svg';

const Header = ({ siteTitle, theme }) => (
  <header className={`navbar navbar-${theme}`}>
    <div className="container">
      <Link to="/" className="navbar--logo">
        <img src={logo} alt={siteTitle} width="200" />
      </Link>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  theme: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
